import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import imageProptypes from "lib/imageProptypes"
import Layout from "components/Layout"
import HeroLego from "legos/HeroLego"
import ArticleText from "components/ArticleText"
import CategoriesDisplay from "components/CategoriesDisplay"
import theme from "theme"

const BlogPost = ({
  data: {
    post: { title, date, categories, content, coverImage },
    prevPost,
    nextPost,
  },
}) => (
  <Layout title="Blog">
    <HeroLego title={title} background={coverImage} mode="standard">
      <Link
        aria-label="Blog Home"
        to="/blog/"
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 5,
          width: 66,
          height: 66,
          background: theme.tan,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          [theme.mobile]: {
            width: 37,
            height: 37,
          },
        }}
      >
        <svg width="14" height="21" viewBox="0 0 14 21" fill="none">
          <path d="M13 1L1 10.5L13 20" stroke={theme.greyBrown} />
        </svg>
      </Link>
    </HeroLego>

    <div
      css={{
        "--gutters": "30px",
        background: theme.gray1,
        padding: "100px var(--gutters)",
        position: "relative",
        zIndex: 2,
        [theme.mobile]: {
          padding: "50px var(--gutters)",
        },
      }}
    >
      <div
        css={{
          fontSize: "15px",
          lineHeight: 1.125,
          letterSpacing: "0.13em",
          textTransform: "uppercase",
          margin: "0 auto 1em",
          maxWidth: 540,
        }}
      >
        {date}
      </div>

      <ArticleText content={content} />

      <div
        css={{
          margin: "64px auto 0",
          maxWidth: 540,
          textAlign: "center",
          [theme.mobile]: {
            marginTop: 40,
          },
        }}
      >
        <h3 css={theme.h3}>Categories</h3>
        <CategoriesDisplay categories={categories} css={{ marginTop: 15 }} />
      </div>
    </div>

    {(prevPost || nextPost) && (
      <Pagination prevPost={prevPost} nextPost={nextPost} />
    )}
  </Layout>
)

export default BlogPost

BlogPost.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
      coverImage: imageProptypes.isRequired,
    }).isRequired,
    prevPost: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
    }),
    nextPost: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}

const Pagination = ({ prevPost, nextPost }) => (
  <div
    css={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 30,
      background: theme.tan,
      position: "relative",
      zIndex: 2,
      [theme.max(550)]: {
        justifyContent: "flex-end",
      },
    }}
  >
    {prevPost ? (
      <Link
        to={prevPost.slug.current}
        aria-label="Previous Post"
        css={{
          marginRight: 60,
          overflow: "hidden",
          [theme.max(550)]: nextPost && { display: "none" },
        }}
      >
        <div
          css={{
            ...theme.h5,
            display: "flex",
            alignItems: "center",
          }}
        >
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            css={{ marginRight: 12 }}
          >
            <path d="M9.07202 1L0.999949 7.5L9.07202 14" stroke="#382F2C" />
          </svg>
          Previous
        </div>

        <div
          css={{
            ...theme.h3,
            marginTop: 24,
            maxWidth: 360,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            [theme.max(550)]: {
              marginTop: 10,
            },
          }}
        >
          {prevPost.title}
        </div>
      </Link>
    ) : (
      <div />
    )}

    {nextPost && (
      <Link
        to={nextPost.slug.current}
        aria-label="Next Post"
        css={{ textAlign: "right", marginLeft: 60, overflow: "hidden" }}
      >
        <div
          css={{
            ...theme.h5,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          Next
          <svg
            width="10"
            height="15"
            viewBox="0 0 10 15"
            fill="none"
            css={{ marginLeft: 12 }}
          >
            <path
              d="M9.07202 1L0.999949 7.5L9.07202 14"
              stroke="#382F2C"
              css={{
                transformOrigin: "center",
                transform: "scaleX(-1)",
              }}
            />
          </svg>
        </div>

        <div
          css={{
            ...theme.h3,
            marginTop: 24,
            maxWidth: 360,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            [theme.max(550)]: {
              marginTop: 10,
            },
          }}
        >
          {nextPost.title}
        </div>
      </Link>
    )}
  </div>
)

Pagination.propTypes = {
  prevPost: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }),
  nextPost: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export const query = graphql`
  query PostQuery($_id: String!, $prevId: String, $nextId: String) {
    post: sanityBlogPost(_id: { eq: $_id }) {
      title
      date(formatString: "MMMM D")
      categories {
        name
      }
      content: _rawContent
      coverImage {
        ...ImageWithPreview
      }
      ...PageMetadata
    }

    prevPost: sanityBlogPost(_id: { eq: $prevId }) {
      title
      slug {
        current
      }
    }

    nextPost: sanityBlogPost(_id: { eq: $nextId }) {
      title
      slug {
        current
      }
    }
  }
`
